<template>
  <el-row align="middle" class="nav-header" type="flex">
    <el-col :span="8" class="flex items-center">
      <router-link class="flex items-center" to="/">
        <img alt="logo" class="nav-logo" src="@/assets/layout/nav_logo.png" />
        <span class="title">{{ projectName }}</span>
      </router-link>
    </el-col>
    <el-col :span="16">
      <ul class="flex items-center justify-end">
        <li>
          <el-dropdown @command="handleCommand">
            <span class="flex items-center">
              <img alt="avatar" class="nav-avatar mr-5" src="@/assets/layout/nav_avatar.png" />
              {{ employeeName }}
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="keyboardShortcuts">快捷键</el-dropdown-item>
              <el-dropdown-item command="clearLocalCache">清除缓存</el-dropdown-item>
              <el-dropdown-item v-if="userInformation.allowPwdChange" command="changePassword">
                修改密码
              </el-dropdown-item>
              <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </el-col>
    <template v-if="visible">
      <ResetPassword :visible.sync="visible" />
    </template>
  </el-row>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { projectName } from '@/config'
  import { GlobalEventType } from '@/constants'

  export default {
    name: 'Header',
    computed: {
      ...mapGetters({
        employeeName: 'user/employeeName',
        userInformation: 'user/userInformation',
      }),
    },
    components: {
      ResetPassword: () => import('@/components/reset-password'),
    },
    data() {
      return {
        projectName,
        visible: false,
      }
    },
    methods: {
      ...mapActions({
        logout: 'user/logout',
      }),
      handleCommand(command) {
        switch (command) {
          case 'logout':
            this.$mgAlert(`您确定要退出${projectName}吗?`, '温馨提示', { type: 'warning' })
              .then(() => {
                return this.logout()
              })
              .then(() => {
                this.$router.push({ name: 'login' })
              })
            break
          case 'changePassword':
            this.visible = true
            break
          case 'keyboardShortcuts':
            this.$root.$emit(GlobalEventType.KEYBOARD_SHORTCUTS)
            break
          case 'clearLocalCache':
            this.$mgConfirm('确定清除本地缓存?', '提示').then(() => {
              LocalForage.clear()
            })
            break
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .nav-header {
    height: 100%;

    .nav-logo {
      height: 20px;
      margin-right: 4px;
    }

    .nav-avatar {
      width: 20px;
    }

    .title {
      font-size: 14px;
      color: #323333;
      font-weight: 600;
    }

    ul li {
      margin-left: 10px;
    }
  }
</style>
